<template>
	<div>
		<headerTop></headerTop>
		<headerMin @search="searchInit"></headerMin>
		<navlist :state="2"></navlist>
		<!-- 主体 -->
		<div class="allGoods">
			<!-- 索引 -->
			<div class="indexes wrap">
				<!-- <span v-if="cateObj1.category_name">{{ cateObj1.category_name }}</span> -->
				<span><span @click="$router.push('/')">首页</span>>全部商品</span><span v-if="categoryName"> >{{categoryName}}</span><span v-if="categoryName2">>{{categoryName2}}</span>
				<!-- <span v-if="cateObj2.category_name"> > {{ cateObj2.category_name }}</span> -->
			</div>
			<div class="content wrap bgw">
				<!-- 索引列表 -->
				<div class="indexesList fl">
					<div :class="{active1:activeType1 == i}" @click="NavTab1(v.id,i,v)" v-for="(v,i) in classTitle" :key="i" class="title">
						{{v.category_name}}
						<img class="fr" src="@/assets/image/Listofcommodities_Open_icon@2x.png" />
						<div v-show="activeType1 == i" v-for="(a,b) in v.childData" @click.stop @click="NavTab2(a.id,b,v)" :key="b" class="list">
							<p class="one">
								<img src="@/assets/image/Listofcommodities_plus_icon@2x.png" alt />
								<!-- <img v-if="activeType2 ==b" src="@/assets/image/Listofcommodities_reduce_icon@2x.png" alt /> -->
								{{a.category_name}}
							</p>
							<div class="txt" v-if="a.childData.length!=0">
								<p v-show="activeType2 == b" @click.stop @click="NavTab3(c.id)" v-for="(c,d) in a.childData" :key="d" class="one">
									{{c.category_name}}
								</p>
							</div>
						</div>
					</div>
				</div>

				<!-- 商品列表 -->
				<div class="goodsList fr">
					<div class="pageTop">
						<span>{{currentPage}}</span>
						/ {{lastPage}}
					</div>
					<!-- 商品详情 -->
					<div v-if="List1.state == 0 && activeType1 == -1" class="noneSearch">
						抱歉，没有找到与 "
						<span>{{List1.title}}</span> " 相关的内容
					</div>

					<div @click="GoGoodsDetails(v)" :key="i" v-for="(v,i) in goodsList" :class="{all_goodsDetails: 'all_goodsDetails', nth4: (i + 1) % 4 == 0}">
						<div class="img_BOx">
							<img :src="v.goods_img" alt />
						</div>
						<p class="one">￥{{v.price}}</p>
						<p class="two">{{v.goods_name}}</p>
						<p class="shopname" v-if="v.shop_name!=undefined">{{v.shop_name}}</p>
						<p class="one">
							评论
							<span>{{v.commentSum}}</span>
							条
						</p>
						<button v-if="v.shop_id!=undefined" @click="GoGoodsOrder(v)">立即购买</button>
					</div>
					<div class="page" v-if="showPage">
						<el-pagination background layout="prev, pager, next" :total="total" :page-size="12" @current-change="changePage"></el-pagination>
					</div>
				</div>
			</div>
		</div>

		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
	</div>
</template>
<script>
	import navlist from "@/components/home_repect/navlist.vue"
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom";

	import {
		getAllCate
	} from "@/request/index"; //所有分类
	import {
		Goods_indexs
	} from "@/request/index"; // 获取 分类或搜索的商品列表
	export default {
		components: {
			headerTop,
			headerMin,
			publicBottom,
			navlist
		},
		data() {
			return {
				searchState: 0,
				classTitle: [], //索引 列表
				goodsList: [], // 商品列表
				List1: [], //搜索 列表
				activeType1: 0,
				activeType2: 0,
				id: '',
				total: 0,
				cateObj1: {}, // 当前选中的科室对象
				cateObj2: {}, // 当前选中的2级科室
				currentPage: 1,
				lastPage: 1,
				showPage: false, // 是否显示分页
				keyword: "", // 搜索关键字
				page: 1,
				categoryName:"",
				categoryName2:"",
			};
		},
		mounted() {
			let active=this.$route.query.active;
			let id=this.$route.query.id;
			let category_id=this.$route.query.category_id;
			if(active!=undefined&&id!=undefined&&category_id!=undefined){
				getAllCate().then(res => {
					console.log(res);
					if (res.code == 1) {
						this.classTitle = res.data;
						this.id = Object.keys(res.data)[0];
						this.activeType1=active;
						this.$axios.post("api/Goods/index2", {
							category_id: category_id,
							goods_id: id,
							is_daili: 0,
							page: 1,
						}).then(res => {
							if (res.data.code == 1) {
								this.goodsList = res.data.data.data;
								this.total = res.data.data.total;
								console.log(this.res);
							}
						})
					}
				});

			}else{
				this.start();
			}
			console.log(active,id,category_id);
		},
		methods: {
			searchInit(e) {
				this.keyword = e;
				this.allGoods();
			},
			Goindex() {
				this.$router.replace({
					path: "/"
				});
			},
			NavTab1(id, i, v) {
				console.log(id, i, v);
				this.categoryName=v.category_name;
				this.id = id;
				this.cateObj1 = v;
				this.cateObj2 = {};
				this.keyword = ''
				if (this.activeType1 == i) {
					this.activeType1 = -1;
					this.categoryName="";
					this.categoryName2="";
				} else {
					this.activeType1 = i;
				}
				this.allGoods();
			},
			NavTab2(id, i, v) {
				this.showPage =false;
				console.log(id, i, v);
				this.categoryName=v.category_name;
				this.categoryName2=v.childData[i].category_name;
				this.id = id;
				this.page=1;
				this.cateObj2 = v.childData[id];
				this.keyword = ''
				if (this.activeType2 == i) {
					this.activeType2 = -1;
				} else {
					this.activeType2 = i;
				}
				this.allGoods();
			},
			GoGoodsOrder(v) {
				this.$router.push({
					name: "商品详情",
					query: {
						id: v.id,
						shopId: v.shop_id
					}
				});
			},
			start() {
				let msg = this.$route.query.search;
				console.log(msg);
				if (msg == undefined) {
					this.allGoods();
				} else {
					this.activeType1 = -1;
				}
				getAllCate().then(res => {
					console.log(res);
					if (res.code == 1) {
						this.classTitle = res.data;
						this.id = Object.keys(res.data)[0];
					}
				});
			},
			// 获取二级药品
			GoGoodsDetails(v) {
				console.log(v);
				this.$axios.post("api/Goods/index2", {
					category_id: this.id,
					goods_id: v.id,
					is_daili: 0,
					page: 1,
				}).then(res => {
					if (res.data.code == 1) {
						this.goodsList = res.data.data.data;
							this.total = res.data.data.total;
						console.log(this.goodsList);
					}else{
						this.$message.error("该商品暂无药店出售")
					}
				})
			},
			// 获取所有商品
			allGoods() {
		
				this.$axios.post("/api/goods/index", {
					category_id: this.id,
					keyword:this.keyword,
					page:this.page,
				}).then(res => {
					if (res.data.code == 1) {
						console.log( res.data.data.data);
						this.goodsList = res.data.data.data;
						this.lastPage = res.data.data.last_page;
						this.total = res.data.data.total;
						this.showPage = true;
					}
				});
			},
			// 点击分页获取商品列表
			changePage(page) {
				this.currentPage = page;
				this.page = page;
				this.allGoods();
			}
		}
	};
</script>
<style lang="less" scoped>
	.shopname {
		color: #4D4D4D !important;
		font-size: 12px !important;
		margin-bottom: 5px !important;
	}

	//  全部商品页面
	.allGoods {

		// 索引
		.indexes {
			height: 44px;
			line-height: 44px;
			font-size: 14px;
		}

		//   没有搜索内容
		.noneSearch {
			padding-top: 40px;
			width: 1000px;
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			text-align: center;

			span {
				color: red;
			}
		}

		.content {
			overflow: hidden;
		}

		// 索引列表
		.indexesList {
			width: 192px;
			.title {
				background: #dddddd;
				line-height: 40px;
				position: relative;
				min-height: 40px;
				text-indent: 12px;

				>img {
					width: 6px;
					height: 10px;
					vertical-align: middle;
					margin-top: 14px;
					margin-right: 12px;
				}
			}

			.list {
				min-height: 32px;
				line-height: 32px;
				text-indent: 12px;
				background: rgba(245, 245, 245, 1);
				border: 1px solid rgba(221, 221, 221, 1);

				img {
					width: 12px;
					height: 12px;
					// vertical-align: middle;
					margin-right: 4px;
				}
			}

			.txt {
				text-indent: 12px;
				background: white;
				border: 1px solid rgba(221, 221, 221, 1);

				p {
					line-height: 30px;
				}
			}
		}

		// 页码
		.pageTop {
			width: 100%;
			height: 40px;
			box-sizing: border-box;
			padding-right: 15px;
			padding-top: 8px;
			position: relative;
			text-align: right;
			background: rgba(245, 245, 245, 1);
			border: 1px solid rgba(230, 230, 230, 1);
			margin-bottom: 10px;

			span {
				color: red;
			}
		}

		// 商品 列表
		.goodsList {
			padding-bottom: 40px;
			display: flex;
			flex-wrap: wrap;
			width: 1000px;
		}

		// 商品详情
		.all_goodsDetails {
			width: 240px;
			background: rgba(255, 255, 255, 1);
			border: 1px solid rgba(204, 204, 204, 1);
			box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
			font-size: 16px;
			margin-bottom: 40px;
			margin-right: 12px;
			box-sizing: border-box;
			.img_BOx {
				width: 100%;
				height: 228px;
			}

			img {
				width: 100%;
				height: 100%;
			}

			p {
				padding-left: 10px;
			}

			span {
				color: #f00000;
			}

			p:nth-of-type(1) {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(240, 0, 0, 1);
				margin-bottom: 6px;
				margin-top: 4px;
			}

			p:nth-of-type(2) {
				height: 37px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(51, 51, 51, 1);
				line-height: 17px;
				margin-bottom: 10px;
			}

			p:nth-of-type(3) {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(51, 51, 51, 1);
				line-height: 20px;
				margin-bottom: 10px;
			}

			button {
				width: 220px;
				height: 32px;
				background: rgba(240, 0, 0, 1);
				border-radius: 4px;
				margin: 10px auto;
				display: block;
				cursor: pointer;
			}
		}

		.nth4 {
			margin-right: 0px;
		}
	}

	.active1 {
		>img {
			transform: rotate(90deg);
		}
	}

	.indexesList>.list {
		transition: height 1s;
	}

	.page {
		width: 100%;
		text-align: center;
	}
</style>
